'use client';

import type { FC } from 'react';
import React, { useMemo } from 'react';
import { BanksOutlined } from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import { proxied } from 'radash';
import { Button } from '~components/button';
import {
  ButtonColors,
  ButtonVariants,
  HomepageLayoutEnum,
} from '~constants/etc';
import PageUrls from '~constants/page-urls';
import { useUserInfo } from '~hooks/use-user';
import WalletContainer from '~containers/wallet/wallet-container';
import request from '~libs/request';
import HttpErrorHandler from '~components/http-error-handler';
import BannerContainer from '~containers/layout/banner-container';
import { useLayoutStore } from '~store/layout-store';
import HomepageGridLayout from '~containers/layout/homepage-grid-layout';
import HomepageSidebarLayout from '~containers/layout/homepage-sidebar-layout';

const AuthorizedContainer: FC = () => {
  const { t } = useTranslation(['homepage', 'buttons', 'menus']);
  const { data: userInfo, mutate: mutateUser, displayName } = useUserInfo();

  const handleSwitchWallet = async (walletId: string): Promise<void> => {
    try {
      await request({
        url: `/wallets/${walletId}/select`,
        method: 'PUT',
      });
      mutateUser();
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  return (
    <>
      <WalletContainer
        activeWalletId={userInfo?.selectedWallet.objectId || ''}
        extra={
          <div className="text-color flex flex-col text-base font-semibold uppercase">
            <p className="text-color-secondary text-xs font-normal">
              {t('welcome-text')}
            </p>
            {displayName || '-'}
          </div>
        }
        handleSwitchWallet={handleSwitchWallet}
        wallets={userInfo?.wallets}
      />
      <div className="mt-2.5 flex flex-col gap-2.5">
        <div className="grid grid-cols-2 gap-2.5">
          <Button
            color={ButtonColors.SUCCESS}
            href={PageUrls.FINANCIAL_DEPOSIT}
          >
            {t('buttons:btn-deposit')}
          </Button>
          <Button
            color={ButtonColors.DANGER}
            href={PageUrls.FINANCIAL_WITHDRAW}
          >
            {t('buttons:btn-withdraw')}
          </Button>
        </div>
        <Button
          color={ButtonColors.PRIMARY}
          href={PageUrls.AFFILIATE}
          variant={ButtonVariants.GHOST}
        >
          <BanksOutlined />
          {t('buttons:btn-affiliate')}
        </Button>
      </div>
    </>
  );
};

const UnauthorizedContainer: FC = () => {
  const { t } = useTranslation('buttons');

  return (
    <div className="flex w-full flex-col gap-4 border-b border-white/10 pb-4 md:flex-row">
      <Button
        className="w-full"
        color={ButtonColors.PRIMARY}
        href={PageUrls.REGISTER}
      >
        {t('btn-register')}
      </Button>
      <Button
        className="w-full"
        color={ButtonColors.PRIMARY}
        href={PageUrls.LOGIN}
        variant={ButtonVariants.GHOST}
      >
        {t('btn-login')}
      </Button>
    </div>
  );
};

const HomePageClientPage: FC<{
  isAuthorized: boolean;
  locale: string;
}> = ({ isAuthorized, locale }) => {
  const { layoutLists: homepageLayout } = useLayoutStore((state) => {
    return {
      layoutLists: state.layoutLists['homepage-layout'],
    };
  });

  const homepageLayouts = proxied((prop: HomepageLayoutEnum) => {
    switch (prop) {
      case HomepageLayoutEnum.Grid:
        return <HomepageGridLayout />;
      case HomepageLayoutEnum.Sidebar:
        return <HomepageSidebarLayout />;
      default:
        return <HomepageGridLayout />;
    }
  });

  const displayHomepage = useMemo(() => {
    if (isAuthorized) {
      return (
        <div className="px-4 sm:px-8">
          <AuthorizedContainer />
          {homepageLayouts[homepageLayout]}
        </div>
      );
    }
    return (
      <div>
        <BannerContainer locale={locale} />
        <div className="px-4 sm:px-8">
          <UnauthorizedContainer />
          {homepageLayouts[homepageLayout]}
        </div>
      </div>
    );
  }, [isAuthorized, homepageLayouts, homepageLayout]);

  return <div className="mb-10 w-full gap-2">{displayHomepage}</div>;
};

export default HomePageClientPage;
