import { get } from 'lodash';
import type { MediaTypeEnum } from '~constants/etc';
import mediaConfig from '~public/media-config.json';

const s3Url = process.env.S3_BUCKET_ASSETS_URL;
const s3Prefix = process.env.S3_BUCKET_ASSETS_PREFIX_STATIC || '';

const buildRootDir = (): string => {
  const rootDir = s3Prefix.split('/')[0];
  return rootDir === 'develop' ? rootDir : 'main';
};

const buildProviderPath = (provider?: string): string =>
  provider ? `/${provider}` : '';

const buildGameCodePath = (gameCode?: string): string =>
  gameCode ? `/games/${gameCode}` : '';

const buildDirPath = (dir?: string): string => (dir ? `${dir}` : '');

const buildExtensionPath = (extension?: string): string => extension || 'jpg';

const getImageName = (props: {
  name: string;
  provider?: string;
  gameCode?: string;
}): string => {
  const { name, provider, gameCode } = props;
  return get(mediaConfig, `${provider}.${gameCode || 'logo'}`, name);
};

const getMediaFromBucket = (props: {
  type: MediaTypeEnum;
  name: string;
  dir?: string;
  provider?: string;
  gameCode?: string;
  extension?: string;
}): string => {
  const { type, name, dir, provider, gameCode, extension } = props;

  const rootDir = buildRootDir();
  const providerPath = buildProviderPath(provider);
  const gameCodePath = buildGameCodePath(gameCode);
  const dirPath = buildDirPath(dir);
  const extensionPath = buildExtensionPath(extension);
  const imgName = getImageName({ name, provider, gameCode });

  return `${s3Url}/${rootDir}/media/${type}${providerPath}${dirPath}${gameCodePath}/${imgName}.${extensionPath}`;
};

export default getMediaFromBucket;
