import { proxied } from 'radash';
import type { FC } from 'react';
import React from 'react';
import {
  AlpacaStudioFilled,
  BanksOutlined,
  BentoFilled,
  CallCenterOutlined,
  CardOutlined,
  CloseCircleOutlined,
  FooterOutlined,
  GiftOutlined,
  MegaphoneOutlined,
  PoolOutlined,
  SlotOutlined,
} from '@packages/icons-react';
import Image from 'next/image';
import { HomepageMenuEnum, HomepageMenuTypeEnum } from '~constants/etc';
import images from '~build-config/images.json';

type IconClassNameType = string | undefined;

export const menuIcons = proxied((menu: HomepageMenuEnum) => {
  switch (menu) {
    case HomepageMenuEnum.Casino:
      return {
        image: images.menuCasino,
        icon: (className?: IconClassNameType) => {
          return <CardOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Gaming:
      return {
        image: images.menuSlot,
        icon: (className?: IconClassNameType) => {
          return <SlotOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Sport:
      return {
        image: images.menuSport,
        icon: (className?: IconClassNameType) => {
          return <FooterOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Lotto:
      return {
        image: images.menuSport,
        icon: (className?: IconClassNameType) => {
          return <PoolOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Reward:
      return {
        image: images.menuReward,
        icon: (className?: IconClassNameType) => {
          return <GiftOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Announcement:
      return {
        image: images.menuAnnouncement,
        icon: (className?: IconClassNameType) => {
          return <MegaphoneOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Contact:
      return {
        image: images.menuContact,
        icon: (className?: IconClassNameType) => {
          return <CallCenterOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Affiliate:
      return {
        image: images.menuContact,
        icon: (className?: IconClassNameType) => {
          return <BanksOutlined className={className} />;
        },
      };
    case HomepageMenuEnum.Gamepaca:
      return {
        image: images.horizontalLogo,
        icon: (className?: IconClassNameType) => {
          return <AlpacaStudioFilled className={className} />;
        },
      };
    case HomepageMenuEnum.All:
      return {
        image: images.horizontalLogo,
        icon: (className?: IconClassNameType) => {
          return <BentoFilled className={className} />;
        },
      };
    default:
      return {
        image: images.horizontalLogo,
        icon: (className?: IconClassNameType) => {
          return <CloseCircleOutlined className={className} />;
        },
      };
  }
});

const MenuIcon: FC<{
  menu: any;
}> = (props) => {
  const { menu } = props;
  const menuTypes = proxied((type: HomepageMenuTypeEnum) => {
    switch (type) {
      case HomepageMenuTypeEnum.TypeImage:
        return (
          <Image
            {...menu.props}
            alt={String(menu.name)}
            height={menu.height}
            src={menuIcons[menu.name].image}
            width={menu.width}
          />
        );
      case HomepageMenuTypeEnum.TypeIcon: {
        return menuIcons[menu.name].icon(menu.className || 'text-4xl');
      }
    }
  });
  return menuTypes[menu.type];
};

export default MenuIcon;
