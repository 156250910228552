import { useCallback } from 'react';
import type {
  GameFeatureListsResApi,
  ProviderGameInterface,
  ProviderInterface,
} from '../types/provider';
import getMediaFromBucket from '~libs/get-media-from-bucket';
import { MediaTypeEnum } from '~constants/etc';

export const useMediaImagePaths = (): {
  withImageLogoPath: (
    provider: ProviderInterface | GameFeatureListsResApi,
  ) => string;
  withImageThumbnailPath: (game: ProviderGameInterface) => string;
} => {
  const getLogoImageName = (providerId: string, isLightMode: boolean): string =>
    `${providerId}-logo${isLightMode ? '-dark' : ''}`;

  const withImageLogoPath = useCallback((provider: ProviderInterface) => {
    const isLightMode = document.documentElement.className.includes('light');
    const logoName = getLogoImageName(
      String(provider.id || provider.providerId),
      isLightMode,
    );

    return getMediaFromBucket({
      type: MediaTypeEnum.Images,
      name: logoName,
      provider: provider.id || provider.providerId,
      dir: '/logo',
      extension: 'png',
    });
  }, []);

  const withImageThumbnailPath = useCallback((game: ProviderGameInterface) => {
    return getMediaFromBucket({
      type: MediaTypeEnum.Images,
      name: 'thumbnail',
      provider: game.provider,
      gameCode: game.gameCode,
      extension: 'webp',
    });
  }, []);

  return {
    withImageLogoPath,
    withImageThumbnailPath,
  };
};
