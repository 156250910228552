import type {
  GameFeatureListsResApi,
  ProviderGameInterface,
  ProviderInterface,
} from 'types/provider';
import type { AxiosResponse, Method } from 'axios';
import type { CurrencyEnum } from '@packages/currency';
import type { KeyedMutator } from 'swr';
import type { Config } from '~hooks/use-request';
import useRequest from '~hooks/use-request';
import type { ProviderTypeEnum } from '~constants/promotion';
import { useMediaImagePaths } from '~hooks/use-media';

export const useGameFeature = (
  currency?: string,
  config?: Config<GameFeatureListsResApi[]>,
) => {
  return useRequest(
    {
      method: 'GET' as Method,
      url: '/providers/game-features',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useProviders = (
  params: {
    type: ProviderTypeEnum;
    currency: CurrencyEnum;
  },
  config?: Config<ProviderInterface[]>,
): {
  data: ProviderInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<ProviderInterface[]>>;
} => {
  const { withImageLogoPath } = useMediaImagePaths();
  const { data, ...rest } = useRequest<ProviderInterface[]>(
    {
      url: '/providers',
      params,
    },
    {
      ...config,
      revalidateOnFocus: false,
      refreshInterval: 0,
      revalidateOnMount: true,
    },
  );

  const getProviderWithLogoPath = (
    providers: ProviderInterface[] | undefined,
  ) => {
    return (
      providers?.map((provider) => {
        const logo = withImageLogoPath(provider);
        return {
          ...provider,
          logo,
        };
      }) || []
    );
  };
  return {
    ...rest,
    data: getProviderWithLogoPath(data),
  };
};

export const useProviderGames = (
  params: {
    providers?: string;
    type: ProviderTypeEnum;
    currency: CurrencyEnum;
  },
  config?: Config<any[]>,
): {
  data: ProviderGameInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<ProviderGameInterface[]>>;
} => {
  const { withImageThumbnailPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<ProviderGameInterface[]>(
    {
      method: 'GET' as Method,
      url: '/providers/games',
      params,
    },
    {
      ...config,
      revalidateOnFocus: false,
      refreshInterval: 0,
      revalidateOnMount: true,
    },
  );

  const getProviderWithThumbnailPath = (
    providers: ProviderGameInterface[] | undefined,
  ) => {
    return (
      providers?.map((game) => {
        const thumbnail = withImageThumbnailPath(game);
        return {
          ...game,
          thumbnail,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: getProviderWithThumbnailPath(data),
  };
};
