import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'radash';
import { parseCookies } from 'nookies';
import type { ProviderInterface } from '../../types/provider';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import ProviderLoadingTemplate from '~components/widget/provider-loading-template';
import type { CurrencyEnum } from '~constants/etc';
import { ArrangementEnum } from '~constants/etc';
import ImageCustomWidget from '~components/widget/image-custom-widget';
import BentoMenuWidget from '~components/widget/bento-menu-widget';
import { useProviders } from '~hooks/use-provider';
import { ProviderTypeEnum } from '~constants/promotion';
import objectToQueryString from '~libs/object-to-query-string';
import { encrypt } from '~libs/encrypt';
import HttpErrorHandler from '~components/http-error-handler';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';

const SportProviderContainer: FC<{
  currency: CurrencyEnum;
  walletObjectId: string | undefined;
  isAuthorized: boolean;
}> = ({ currency }) => {
  const router = useRouter();
  const { t } = useTranslation('errors');
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const { data: sportsbookProviders, isLoading: isLoadingProvidersSport } =
    useProviders({
      type: ProviderTypeEnum.SPORTBOOK,
      currency,
    });

  const requestOpenSportsbooksProvider = (
    provider: ProviderInterface,
  ): void => {
    try {
      const gameCode = get(provider, 'meta.defaultGame.gameCode');
      const currencyFiltered = provider?.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.SPORTBOOK,
        providerId: provider?.objectId,
        providerName: provider?.id,
        gameCode,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      window.open(
        `/${locale}${PageUrls.OPEN_GAME}?qs=${encrypt(queryString)}`,
        '_self',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenProvider = (provider: ProviderInterface): void => {
    if (!provider?.meta?.defaultGame) {
      return router.push(`${PageUrls.SPORT}/${provider.id}`);
    }
    requestOpenSportsbooksProvider(provider);
  };

  if (isLoadingProvidersSport) {
    return <ProviderLoadingTemplate />;
  }

  return (
    <div className="flex flex-col gap-2">
      {sportsbookProviders?.map((provider, i) => (
        <BentoMenuWidget
          arrangement={ArrangementEnum.Horizontal}
          icon={
            <ImageCustomWidget
              alt={`game-${provider.id}`}
              className="sm:mt-0"
              image={provider?.logo || ''}
            />
          }
          key={i}
          onClick={() => {
            handleOpenProvider(provider);
          }}
          text={provider.title || t('not-found-provider')}
        />
      )) || []}
    </div>
  );
};

export default SportProviderContainer;
